import { copyText } from 'language';
import theme from 'theme';
import storeLocation from 'utils/storeLocation';

const cloverIframeStyles = {
  body: {
    display: 'flex',
  },
  input: {
    fontSize: theme.theme.typography.h6.fontSize,
    margin: theme.theme.spacing(0.5),
    border: `1px solid ${theme.theme.palette.primary.main}`,
    borderRadius: theme.theme.shape.borderRadius,
    padding: theme.theme.spacing(2),
  },
};

const loadCloverScript = ({ setCloverReady, setMessage }) => {
  const scriptSrc = process.env.REACT_APP_CLOVER_IFRAME_URL;

  if (!scriptSrc) {
    setMessage(copyText.Cart.CheckoutStepper.errorLoadingCloverScript);
    return;
  }

  // Check if the script already exists
  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    const script = document.createElement('script');
    script.src = `${scriptSrc}?timestamp=${Date.now()}`; // Adding timestamp to bypass cache
    script.async = true;

    script.onerror = () => {
      setMessage(copyText.Cart.CheckoutStepper.errorLoadingCloverScript);
    };

    document.body.appendChild(script);
    setCloverReady(true);
  } else {
    setCloverReady(true);
  }
};

// Function to load the Clover iframe
const loadIframe = ({ cloverContainerId, setMessage }) => {
  try {
    const storeDetails = storeLocation.getLocationStorage();
    const merchantId = storeDetails?.custom?.fields?.cloverMerchantId;
    const cloverPublicKey = storeDetails?.custom?.fields?.cloverPublicKey;
    const clover = new window.Clover(cloverPublicKey, {
      merchantId,
    });

    const elements = clover.elements();
    const cardElement = elements.create('CARD', cloverIframeStyles);
    cardElement.mount(`#${cloverContainerId}`);

    // Return the clover instance with cardElement
    return { clover, cardElement };
  } catch (err) {
    setMessage(copyText.Cart.CheckoutStepper.errorLoadingClover);
  }
  return null;
};

// Function to handle payment processing
const handleProcessPayment = async ({ cloverInstanceRef, setMessage }) => {
  try {
    const { clover, cardElement } = cloverInstanceRef?.current ?? {};
    if (clover && cardElement) {
      const result = await clover.createToken(cardElement);
      // SUNRISE(toddf): handle result
      if (result && result.token) {
        // SUNSET: change next line to not display here's the token (for QA purposes only for mpos-4639 )
        setMessage(
          `${copyText.Cart.CheckoutStepper.tokenizationSuccessful} Here's the token: ${result.token}`,
        );
        // SUNRISE: Send result.token to server
      } else {
        setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
      }
    } else {
      setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
    }
  } catch (err) {
    setMessage(copyText.Cart.CheckoutStepper.errorProcessingPayment);
  }
};

const unloadCloverIframe = (cloverInstance) => {
  if (cloverInstance && cloverInstance.parentNode) {
    // Remove the iframe from the DOM
    cloverInstance.parentNode.removeChild(cloverInstance);
  }
};

export default {
  loadCloverScript,
  loadIframe,
  handleProcessPayment,
  unloadCloverIframe,
};
